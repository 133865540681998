// 亦中日子
<template>
  <div class="campusLife">
    <TabPageLayout activeCode="xiaoyuanshenghuo">
      <div class="container internationalCourse">
        <div v-html="typeBz" style="margin-bottom: 50px;" />

        <div class="div_tit" v-for="(block, index) in blocks" :key="block.id" :class="'div_tit' + index">
          <h2>{{ block.title }}</h2>
          <div
            v-if="block?.code ? block?.code == 'CampusCultureDay' : false"
            class="div_mod7 specialEvents"
          >
            <SwiperContainer2 :typeId="block.id + ''" />
          </div>

          <div v-else>
            <!-- 文章换成轮播 -->
            <!-- <div v-if="blockArticles[index]?.length">
              <ArticleCard2
                v-for="(article, subIndex) in blockArticles[index]"
                :key="article.id"
                :article="article"
                :showTitle="false"
                activeCode="xiaoyuanshenghuo"
                :class="{
                  dlSp: (subIndex + 1) % 2 === 0,
                }"
              />
            </div> -->
            <dl class="lunbo_div" v-for="(item,i) in blockArticles[index]" :key="i">
              <dt class="right">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,v) in imgArr[index]" :key="v">
                      <img :src="item.img" alt="" />
                    </div>
                  </div>
                </div>
                <div class="direction">
                  <span class="s_prev"><i></i></span>
                  <span class="s_next"><i></i></span>
                </div>
              </dt>
              <dd class="left">
                <p class="text">{{item.des}}</p>
                <p class="more" @click="toDetail(item)">查看更多 > </p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import portalApi from '../../../../src/lvya-common/src/api/eos/portal'
import ArticleCard2 from './components/ArticleCard2.vue'
import DoTime from '../../../../src/lvya-common/src/utils/Time/DoTime'
import SwiperContainer2 from './components/SwiperContainer2.vue'

export default {
  components: {
    TabPageLayout,
    ArticleCard2,
    SwiperContainer2,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return this.nav?.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[0]?.id || undefined
    },
    typeBz() {
      return this.nav.find((item) => item.code === 'xiaoyuanshenghuo')?.stypes[0]?.bz
    },
    blocksLength() {
      return this.blocks.length
    }
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
      imgArr: [],
      // imgs: [
      //   { img: 'https://notoneless-oss.lvya.org/file/202502/ce405dda-5170-4886-981e-03ea16ae84a5.JPG' },
      //   { img: 'https://notoneless-oss.lvya.org/file/202502/6c8e818c-8183-489b-afa9-49a467453eec.png' },
      // ],
    }
  },
  methods: {
    formatDate(time) {
      return DoTime.dateFormat('MM月dd日', time)
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      this.blocks = categories.list
      await this.getArticles()
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(localStorage.getItem('portalSchoolInfo'))
      this.blockArticles = []
      this.imgArr = []
      for (let block of this.blocks) {
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        this.blockArticles.push(resData?.list || [])
        let resImg = await portalApi.getImgs({ typeId: block.id, }) // 栏目下的图片
        console.log(resImg, '22222')
        this.imgArr.push(resImg || [])
      }
      for (let index = 0; index < this.blocks.length; index++) {
        console.log(index, `.div_tit${index}`, '111111')
        if (index < 4) {
          new Swiper(`.div_tit${index} .swiper-container`, {
            loop: false,
            slidesPerView: 1, // 一行显示3个
            spaceBetween: 30,
            navigation: {
              nextEl: `.div_tit${index} .lunbo_div .s_next`,
              prevEl: `.div_tit${index} .lunbo_div .s_prev`,
            },
          })
        }
      }
    },
    toDetail(article) {
      this.$router.push({ path: '/portalDetails', query: { id: article.id, activeCode: 'xiaoyuanshenghuo', }})
    },
  },
  async mounted() {
    await this.getBlocks()
    // await this.getArticles()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/campusLife.css";
.lunbo_div{
  width: 100%;
  padding: .6rem .7rem 1.4rem .7rem;
  background: #f5f5f5;
  overflow: hidden;
  margin: .4rem 0;
  margin-bottom: .8rem;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  .left{
    width: 45%;
    float: left;
    // background: green;
    .text{
      color: #555;
      opacity: .8;
      line-height: 40px;
      font-size: 0.24rem;
    }
    .more{
      font-size: .24rem;
      color: #81b934;
      cursor: pointer;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .right{
    width: 42%;
    float: right;
    // background: red;
    position: relative;
    .swiper-slide{
      img{
        min-height: 305px;
        max-height: 405px;
      }
    }
    .direction{
      width: 150px;
      display: flex;
      position: absolute;
      bottom: -50px;
      right: 10px;
    }
  }
}
@media screen and (max-width: 1023px) {
  .lunbo_div{
    padding: 0.6rem 0.4rem .4rem 0.4rem;
    .left, .right{width: 100%;}
    .left{
      .text{
        line-height: 30px;
      }
    }
    .direction{
      display: none !important;
    }
    .right{
      margin-bottom: 30px;
      .swiper-slide{
      img{
        min-height: 200px;
        max-height: 200px;
      }
    }
    }
  }
}
</style>

